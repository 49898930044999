<template>
    <el-table :data="invoicesData" v-loading="$waiting.is('loading.found_invoices')" :row-class-name="tableRowClassName" size="mini" class="found-invoices-container">
        <el-table-column prop="hasDebt" width="26">
            <template slot-scope="scope">
                <span v-if="scope.row.hasDebt" class="flex h-4 w-4 rounded-full bg-black" />
            </template>
        </el-table-column>
        <el-table-column prop="clientNumber" label="Klient" show-overflow-tooltip>
            <template slot-scope="scope"> {{ scope.row.clientNumber }} {{ scope.row.clientName }} </template>
        </el-table-column>
        <el-table-column prop="customerName" label="Kund namn" show-overflow-tooltip />
        <el-table-column prop="invoiceNumber" label="Faktura nr" width="90" />
        <el-table-column prop="grossAmount" label="Gross" width="120" align="right" sortable>
            <template slot-scope="scope"> {{ scope.row.grossAmount | swedishNumberFormat }} {{ scope.row.currency }} </template>
        </el-table-column>
        <el-table-column prop="restAmount" label="Rest" width="90" align="right" sortable>
            <template slot-scope="scope">
                {{ scope.row.restAmount | swedishNumberFormat }}
            </template>
        </el-table-column>
        <el-table-column prop="matches" label="M" width="50" />
        <el-table-column width="50" align="right">
            <template slot-scope="scope">
                <el-tooltip transition="none" class="item" effect="light" content="Matcha faktura" placement="top" :enterable="false" :open-delay="500">
                    <el-button @click="editInvoice(scope.row.id)" type="" class="p-1">
                        <i class="fa-solid fa-link text-base" style="color: #317e0d;" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                        </svg> -->
                    </el-button>
                </el-tooltip>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        invoicesData: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        editInvoice(id) {
            this.$emit("editInvoice", id);
        },
        tableRowClassName({ row }) {
            if (row.matched == true) {
                return "selected-row";
            }
            return "";
        },
    },
};
</script>
<style>
.found-invoices-container .selected-row {
    background-color: #e9faff;
}
</style>
